import React from 'react';
import no_image from '../assets/images/no_image.jpg';

const ChairmanMessage = () => {
  return (
    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">Chairman Message</h2>
            <div class="d-flex justify-content-center">
              <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>Chairman Message</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 pt-5 pb-5">

            <div class="d-flex flex-column align-items-center">
              <img className='img-fluid' src={no_image} alt="Example" />
              <h5 className="text-center vasd_h5">Name:</h5>
              <h5 className="text-center vasd_h5">Contact: 008855</h5>
            </div>

          </div>
          <div className=" col-12 col-md-6 pt-5 pb-5">
            <h3 className='text-center'>Chairman Message</h3>

            Village Association for Social Development (VASD) was started in a small village of Manikganj district from the development thinking of some of the rural activists in 1995. With an organizational approach of innovation and learning that had been the essential components, VASD has entered into its 23rd years of development initiatives. During this good period of time, many changes have been brought with its perceptions of new needs, priorities and new expectations. Even innovation has been remained firmly committed to its mission. During the period, the organisation has acquired diverse experience from the real field of implementation. However, VASD has endeavored to be sensitive and responsive.
            <div className="text-center p-4" style={{ width: '100%', }}>
              <span className='vasd_h2  m-2'><i class="bi bi-facebook"></i></span>
              <span className='vasd_h2  m-2'><i class="bi bi-twitter-x"></i></span>
              <span className='vasd_h2  m-2'><i class="bi bi-skype"></i></span>
              <span className='vasd_h2  m-2'><i class="bi bi-whatsapp"></i></span>
            </div>
          </div>
        </div>

      </div>
    </section>


  );
};

export default ChairmanMessage;
