import React from 'react';
import about from '../assets/images/about.jpg';

const AboutUs = () => {

  const iconStyle = {
    color: '#002866',

  };

  return (

    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">About Us </h2>
            <div class="d-flex justify-content-center">
              <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>About Us </span>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="py-5 ">
        <div className="container">
          <div className="row">
            <h1 className='text-center vasd_h3'>ORGANIZATIONAL OVERVIEW</h1>
            <div className="col-12 col-sm-5 pt-5 pb-5">
              <p className='m-2'>
                <img src={about} alt="React Logo" className="img-fluid" />
              </p>
            </div>
            <div className="col-12 col-sm-7 pt-5 pb-5">
              <p className='m-2'> Village Association for Social Development (VASD) was started in a small village of Manikganj district from the development thinking of some of the rural activists in 1995. With an organizational approach of innovation and learning that had been the essential components, VASD has entered into its 23rd years of development initiatives. During this good period of time, many changes have been brought with its perceptions of new needs, priorities and new expectations. Even innovation has been remained firmly committed to its mission. During the period, the organisation has acquired diverse experience from the real field of implementation. However, VASD has endeavored to be sensitive and responsive.
              </p>
              <p> Since its inception in 1995, (VASD) has been a visionary and people-centric organisation that established VASD as a popular organisation to the people it works with. Keeping the same commitment and determination it has applied to its mission, it has been continuing its mission very successfully. The principles of objectivity, built on accountability, efficiency and effectiveness, which VASD adopted since the very beginning, have remained immutable in the face of all the challenges the organization has encountered. These key tenets have been at the core of the initiatives that VASD has undertaken over the years.
              </p>
              <p> Presently VASD is playing an important role which acts as a catalyst for development to achieve inclusion of the people who are excluded socially, politically, economically and by many other means. It has been striving to build trust, strengthen communication and foster productive relationships both within the organization as well as with its program participants. Through pursuing its objective of reducing poverty, supporting human rights and very particularly improving the status of women and the people with disabilities. Thus the organisation is making efforts to bring about changes in the lives of the people.
              </p>

            </div>
          </div>

        </div>
      </div>

      <section className='mt-5 mb-5'>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h1 className='vasd_h1 text-center mb-sm-5 mb-2 fs-2'>MAJOR OBJECTIVES</h1>
              <ul className="list-unstyled">
                <li><i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i>  To provide target people with proper knowledge, skill and attitude</li>
                <li><i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> To ensure beneficiaries’ access to basic services</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> To ensure economic empowerment of the target people</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> To achieve empowerment of the women</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> To ensure communities’ participation in mobilizing local resources </li>
                <li><i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i>  To ensure social inclusion of the marginalized people</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> Building disability inclusive society and protect rights of the people with disability</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> Environment conservation and adaptation with natural calamities</li>
                <li><i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i>  Building resilience to natural shocks. </li>
              </ul>
            </div>

            {/* <!-- Right side: Bangladesh Map --> */}
            <div class="col-md-6">
              <div class="map-container position-relative">
                <div class="">
                  <h1 className='vasd_h1 text-center mb-sm-5 mb-2 fs-2'>CORE VALUES</h1>
                  <h4 className='vasd_h4'>VASD is committed to its fundamental beliefs, that are - </h4>
                  <ul className="list-unstyled">
                    <li> <i class="bi bi-check-circle"></i> Professionalism </li>
                    <li> <i class="bi bi-check-circle"></i>  Transparency</li>
                    <li>  <i class="bi bi-check-circle"></i>  Accountability</li>
                    <li><i class="bi bi-check-circle"></i> Participation</li>
                    <li> <i class="bi bi-check-circle"></i> Cooperation</li>
                    <li> <i class="bi bi-check-circle"></i> Credibility</li>
                    <li> <i class="bi bi-check-circle"></i> Equity</li>
                    <li> <i class="bi bi-check-circle"></i> Innovation</li>
                    <li> <i class="bi bi-check-circle"></i> Dynamismv</li>
                    <li> <i class="bi bi-check-circle"></i> Gender responsiveness.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section >
        <div className="container">
          <div className="row">
            <div className='col-md-12'>
              <div className='vasd_h1 text-center mb-sm-5 mb-2 fs-1'> Member of Network Or Forum</div>
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th className='text-center'>Network OR Forum</th>
                    <th className='text-center'>Forum Identity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>National Forum of Organization Working with Disabled (NFOWD)</td>
                    <td>A Non-Govt. National organization working on disabled people</td>
                  </tr>
                  <tr>
                    <td>Association of Development Agencies in Bangladesh (ADAB)</td>
                    <td>Association of NGO’s in Bangladesh</td>
                  </tr>
                  <tr>
                    <td>NGO Forum for Safe Water Supply & Sanitation</td>
                    <td>Department of Public Health & Education a National Public Health control Sector.</td>
                  </tr>
                  <tr>
                    <td>Credit and Development Forum (CDF)</td>
                    <td>A Non-Govt. credit development & Management Organization</td>
                  </tr>
                  <tr>
                    <td>Podokhep consortium on HIV/AIDS</td>
                    <td>A National and International (Save the Children USA) joint venture organization working on HIV/AIDS</td>
                  </tr>
                  <tr>
                    <td>Bangladesh Manobadhikar Samonnoy Parishad (BMSP)</td>
                    <td>A National Non-Govt. organization working on Human Rights</td>
                  </tr>
                  <tr>
                    <td>Coalition for the Urban Poor (CUP)</td>
                    <td>A National Forum of organization who working with urban poor.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </section>    {/*end network */}
    </section>

  );
};

export default AboutUs;
